footer {
  background-color: #000;
  color: #fff;
  border-top: 20px solid $primary-color;
  padding: 10px 0 0;

  h3,
  a,
  p,
  li {
    font-weight: 600;
    text-align: center;
  }

  ul {
    padding-left: 0;

    li {
      display: block;
    }
  }

  a {
    line-height: 42px;
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .googlemap {
    width: 80%;
    height: 300px;
    margin: auto;
    display: block;
  }

  .contactinformation {
    p {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }

  #copyrightbox {
    background: $primary-color;

    .col-12 {
      text-align: center;

      span,
      a {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
}